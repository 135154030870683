<template>
  <div class="col-12 col-sm-6">
    <div class="login--col">
      <h2>{{ $t("login.login_title") }}</h2>
      <form @submit.prevent="apiLogin" class="login--form login--form--login">
        <input
          type="text"
          v-model="email"
          :placeholder="$t('login.login_email')"
          class="login--form--input"
        />
        <input
          type="password"
          v-model="password"
          :placeholder="$t('login.login_password')"
          class="login--form--input"
        />
        <button type="submit" class="login--form--submit btn--blk">
          {{ $t("login.login_button") }}
        </button>
      </form>
      <div class="login--form--input--error" v-if="erreMessage != ''">
        {{ erreMessage }}
      </div>
      <a href="" class="crAc" @click.prevent="openNewProfileModal">{{
        $t("login.login_register")
      }}</a>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import { mapState, mapActions } from "vuex";
export default {
  name: "loginForm",
  data() {
    return {
      lang: Cookies.get("HKGA-MAINSITE-LANGUAGE"),
      email: "",
      password: "",
      erreMessage: ""
    };
  },

  methods: {
    ...mapActions("common", ["setUser", "setToken"]),

    openNewProfileModal() {
      this.$emit("openNewAccountProfileModal");
    },

    async apiLogin(e) {
      e.preventDefault();
      try {
        const response = await fetch(
          `${process.env.VUE_APP_BASE_API_URL}/login`,
          {
            method: "POST",
            mode: "cors",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ email: this.email, password: this.password })
          }
        );

        if (!response.ok || response.status == 401) {
          throw new Error("API Error");
        }

        // const data = await response.json();
        const { user, token } = await response.json();

        if (token) {
          localStorage.setItem("user", JSON.stringify(user));
          localStorage.setItem("token", token);
        }

        // Set the user and token for the global variables
        this.setUser(user);
        this.setToken(token);

        // Set the error message to empty
        this.erreMessage = "";

        // redirect to profile page
        this.$router.push("/" + this.lang + "/profile");
      } catch (error) {
        this.erreMessage = this.$t("login.login_error_invalid_email_pw");
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
